<template>
  <div class="goods-list">
    <div class="table-title mt20">
      <a-alert
        message="上传文件时请将文件名修改为将要上传的校区名。"
        showIcon
        type="info"
      />
      <div class="ml20 mt20">
        <p>
          <a href="/xlxs/学生及家长模板.xlsx">学生及家长信息模板下载</a>
        </p>
        <!-- <a-upload
          action="http://api.lexiangspace.com/sys/fs"
          :fileList="picZipFileList"
          accept=".zip"
          @change="picZipChange"
        >
          <a-button class="my-button" type="primary">
            <a-icon type="upload" />用户头像导入
          </a-button>
        </a-upload>-->
        <a-upload
          :action="actions"
          :fileList="userInfoFileList"
          accept=".xlsx, .xls"
          @change="userInfoChange"
        >
          <a-button class="my-button" type="primary">
            <a-icon type="upload" />用户信息导入
          </a-button>
        </a-upload>
      </div>
      <p class="total">共{{ total }}条</p>
    </div>
    <div class="table-menu">
      <div class="table-menu-right">
        <a-input-search
          placeholder="请输入用户关键字"
          @search="onSearch"
          v-model="keyword"
          enterButton="查找"
          >查找</a-input-search
        >
        <a-button
          class="my-button"
          type="primary"
          style="margin-left:20px"
          @click="exportInformation"
        >
          <a-icon type="vertical-align-bottom" />用户信息导出
        </a-button>
      </div>
      <span class="senior-search" @click="senoirSearch" v-if="!isFlag"
        >高级搜索</span
      >
    </div>
    <button-search
      :schoolList="schoolList"
      :entranceYear="entranceYear"
      :isFlag="isFlag"
      :isToS="false"
      @putAway="senoirSearch"
      @seniorSearch="getSearchList"
      @clear="clear"
      @changeField="changeField"
    ></button-search>
    <div class="my-table">
      <a-table
        :columns="columns"
        :loading="loading"
        :dataSource="data"
        align="center"
        rowKey="id"
        :pagination="false"
      >
        <template slot="headPic" slot-scope="text">
          <div style="width: 50px; height: 50px; overflow:hidden;">
            <img :src="getPhoto(text)" width="50px" />
          </div>
        </template>
        <template slot="name" slot-scope="name, record">
          <div class="price-box" style="display: flex;">
            <div style="position:relative">
              {{ record.name }}
              <div class="msg-f dp-hide" v-if="record.remark">
                <p>备注：{{ record.remark }}</p>
              </div>
            </div>
          </div>
        </template>
        <template slot="intoYear" slot-scope="intoYear">
          <div class="price-box" style="display: flex;">
            <div>
              {{ intoYear }}
            </div>
          </div>
        </template>
        <span slot="action" slot-scope="action, record">
          <router-link :to="`/addVip/${record.id}?schoolId=${record.schoolId}`"
            >编辑</router-link
          >
          <a-divider type="vertical" />
          <a href="javascript:;" @click="DeletePro(record.schoolId, record.id)"
            >删除</a
          >
        </span>
      </a-table>
      <div style="text-align: right;" class="mt10 mb10 mr10">
        <a-pagination
          v-model="current"
          :total="total"
          :defaultPageSize="10"
          @change="pageChange"
        />
      </div>
    </div>
    <a-modal :title="fileName" v-model="userInfoVisible" :footer="null" :closable="false">
      <a-alert
        message="请确保您上传的文件名与本学校同名，否则不予上传。"
        banner
      />
      <div class="mb20 mt20">
        <label class="ml20">发送短信：</label>
        <a-switch
          :checked="sendText"
          checked-children="开"
          un-checked-children="关"
          @change="sendText = !sendText"
        />
      </div>
      <div class="mb20 mt20" v-if="sendText">
        <label class="ml20">短信模板：</label>
        <a-select v-model="smsCode" style="width: 200px">
          <a-select-option
            v-for="tmplt in smsTemplateList"
            :key="tmplt.code"
            :value="tmplt.code"
            >{{ tmplt.name }}</a-select-option
          >
        </a-select>
      </div>
      <div class="mb20 mt20" v-if="sendText">
        <label class="ml20">效果展示：</label>
        <a-card style="width: 340px; display: inline-flex" v-if="smsCode">
          <span>{{
            smsTemplateList.find(tmplt => {
              return tmplt.code === smsCode;
            }).content
          }}</span>
        </a-card>
      </div>
      <div style="display: flex;justify-content:space-between;">
        <div style="display:flex;justify-content:flex-start" v-if="loadingFlag">
          <a-icon
            type="loading"
            style="width:20px;height:20px;margin-right: 8px;margin-top: 2px;"
          />
          <p>{{ loadingText }}</p>
        </div>
        <div style="margin-left:20px">
          <a-button
            @click="
              userInfoVisible = false;
              sendText = true;
              smsCode = '';
            "
            >取消</a-button
          >
          <a-button
            :disabled="isDisabled"
            class="ml10"
            type="primary"
            @click="userInfoHandleOk"
            >确认</a-button
          >
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
// importUser
import {
  importUserHeaders,
  importUser,
  getImportProgress,
  getSmsTemplateList
} from "../../serve/member";
import { getFileName } from "@/serve/file";
import buttonSearch from "@/components/button-search/buttonSearch";
import global from "global";
import { advancedScreen } from "@/mixins/advancedScreen";
import { setTimeout, clearInterval, setInterval } from "timers";
const columns = [
  {
    dataIndex: "headPic",
    key: "headPic",
    title: "用户头像",
    scopedSlots: { customRender: "headPic" }
  },
  {
    dataIndex: "name",
    key: "name",
    title: "中文姓名",
    scopedSlots: { customRender: "name" }
  },
  {
    title: "英文姓名",
    dataIndex: "nameForeign",
    scopedSlots: { customRender: "nameForeign" }
  },
  {
    title: "国际地区",
    dataIndex: "country"
  },
  {
    title: "学生编号",
    dataIndex: "no"
  },
  {
    title: "家长手机号",
    dataIndex: "phone"
  },
  {
    title: "入学年份",
    dataIndex: "intoYear",
    scopedSlots: { customRender: "intoYear" }
  },
  {
    title: "班级",
    dataIndex: "team"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];

export default {
  name: "vipList",
  components: {
    buttonSearch
  },
  mixins: [advancedScreen],

  data() {
    return {
      userInfoForm: this.$form.createForm(this),
      userInfoVisible: false,
      data: [],
      columns,
      res: [],
      loading: false, // 加载状态
      picZipFileList: [], // 用户头像
      userInfoFileList: [], // 用户信息
      selectSchoolId: 0, // 上传用户头像和用户信息前必须先选择学校
      selectSchoolName: "", // 上传用户头像和用户信息前必须先选择学校
      type: "STUDENT",
      responseId: "",
      fileName: "",
      isDisabled: false,
      loadingText: "正在导入中, 请稍等",
      loadingFlag: false,
      timer: null,
      sendText: true,
      smsTemplateList: [],
      smsCode: ""
    };
  },
  computed: {
    actions() {
      return global.file.uploadUrl + "/sys/fs/";
    }
  },
  created() {
    let selectSchool = JSON.parse(sessionStorage.getItem("school_userInfo"));
    this.selectSchoolName = selectSchool.schoolName;
    this.getSmsTemplateList();
  },
  methods: {
    // excel模板下载
    downLoad() {
      getFileName("047d8cf9b2f19d716ebcb966171a4573", { name: "基本信息模板" })
        .then(res => {
          this.$message.success("模板下载成功");
        })
        .catch(() => {
          this.$message.error("下载失败，请稍后重试");
        });
    },
    // 用户信息导出
    exportInformation() {
      let token = window.sessionStorage.getItem("school_token");
      let url =
        global.file.url +
        `/school/schools/0/users/export?access_token=${token}&type=STUDENT&team=${this.params.team}&intoYear=${this.params.intoYear}`;
      window.open(url);
    },
    picZipChange(info) {
      let fileList = [...info.fileList];
      if (info.file.status === "done") {
        importUserHeaders(this.selectSchoolId, {
          fileId: info.file.response.id,
          type: 0
        })
          .then(() => {
            this.$message.success("用户头像上传成功");
          })
          .catch(err => {
            this.$message.error(
              err.data.message || "用户头像上传失败，请稍后再试"
            );
          });
      } else if (info.file.status === "error") {
        this.$message.error("用户头像上传失败，请稍后再试");
      }

      this.picZipFileList = fileList;
    },
    // 用户信息上传
    userInfoChange(info) {
      if (info.file.status === "done") {
        this.userInfoVisible = true;
        this.responseId = info.file.response.id;
        this.fileName = info.file.name;
        if (this.fileName.split(".")[0] !== this.selectSchoolName) {
          this.isDisabled = true;
        } else {
          this.isDisabled = false;
        }
        // importUser(this.selectSchoolId, { fileId: info.file.response.id, type: this.type }).then(() => {
        //   this.$message.success('用户信息上传成功')
        // }).catch(err => {
        //   this.$message.error(err.data.message || '用户信息上传失败，请稍后再试')
        // })
      } else if (info.file.status === "error") {
        this.$message.error("用户信息上传失败，请稍后再试");
      }
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.userInfoFileList = fileList;
    },
    async userInfoHandleOk() {
      this.loadingFlag = true;
      let { uuid } = await importUser(this.selectSchoolId, {
        fileId: this.responseId,
        type: "STUDENT",
        smsCode: this.smsCode
      });
      this.timer = setInterval(() => {
        getImportProgress(0, { uuid })
          .then(res => {
            if (res.status === "FINISHED") {
              let params = {
                page: 0,
                rows: 10,
                type: "STUDENT",
                team: "",
                intoYear: "",
                keyword: ""
              };
              this.loadingFlag = false;
              this.userInfoVisible = false;
              clearInterval(this.timer);
              this.$message.success("导入已完成");
              this.getList(0, params);
            }
          })
          .catch(err => {
            this.$message.error(
              err.data.message || "用户信息上传失败，请稍后再试"
            );
          });
      }, 3000);
    },
    async handleChange(val) {
      this.params.type = val;
      this.params.keyword = this.keyword;
      this.getList(0, this.params);
    },
    isShow(state) {
      if (state === 100) {
        return true;
      } else if (state === 0) {
        return false;
      }
    },
    getSmsTemplateList() {
      getSmsTemplateList(this.selectSchoolId).then(res => {
        this.smsTemplateList = res.filter(tmplt => {
          return tmplt.code === "SMS_NEW_SCHOOL_STUDENT";
        });
      });
    }
  },
};
</script>

<style lang="less" scoped>
.goods-list {
  border: 1px solid #cccccc;
  .table-title {
    .title {
      font-weight: 600;
      color: black;
      font-size: 15px;
      margin: 10px 0 10px 10px;
    }
    .total {
      text-align: right;
      margin: 10px 10px 10px 10px;
    }
  }
}
.table-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 95%;
  margin-left: 14px;
  .senior-search {
    color: #e69b47;
    width: 80px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: #f38e1c;
    }
  }
  .table-menu-right {
    // height: 32px;
    display: flex;
    width: 100%;
    .ant-input-group-wrapper {
      width: 25% !important;
    }
    .my-input {
      height: 32px !important;
    }
  }
}
.my-table {
  .price-box {
    > div:hover .msg-f {
      display: block;
      z-index: 999;
    }
    // display: flex;
    // flex-direction: column;
    .msg-f {
      width: 220px;
      text-align: left;
      padding: 10px 20px;
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 3px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      position: absolute;
      top: -18px;
      left: -240px;
      &:before {
        content: "";
        position: absolute;
        top: 19px;
        right: -10px;
        display: inline-block;
        border-top: 9px solid transparent;
        border-left: 9px solid #ddd;
        border-bottom: 9px solid transparent;
      }
      &:after {
        content: "";
        position: absolute;
        top: 19px;
        right: -9px;
        display: inline-block;
        border-top: 9px solid transparent;
        border-left: 9px solid #fff;
        border-bottom: 9px solid transparent;
      }
    }
    .price-show {
      color: red;
    }
  }
}
.editable-row-operations a {
  margin-right: 8px;
}
</style>
<style lang="less">
.table-title {
  .ant-upload-list {
    display: inline-block;
    // width: 100px;
  }
}
.ant-upload-list-item-name {
  padding-right: 20px;
}
</style>
